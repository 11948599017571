<template>
  <v-container fluid :class="{ 'pb-0 mb-0': !fromFeature, 'py-0 my-0': fromFeature }">
    <v-card style="z-index: 99" elevation="1">
      <v-toolbar color="primary" dark elevation="0" v-if="fromFeature">
        <v-row class="p-0 m-0 text-left text-h5">
          <v-col cols="12" large>Visualisation des secteurs</v-col>
        </v-row>
      </v-toolbar>
      <v-row>
        <v-col cols="12">
          <v-btn
            label
            v-for="(type, key) in types"
            :key="key"
            :color="type.color"
            class="ma-1 text-center"
            :outlined="!(selectedService === type.id)"
            :dark="selectedService === type.id"
            @click="selectedService = type.id"
            small
            elevation="0"
          >
            <v-icon small class="mr-1">{{ type.icon }} fa-fw</v-icon> {{ type.name }}
          </v-btn>
        </v-col>
      </v-row>
      <div style="position: relative">
        <l-map style="height: calc(95vh - 70px)" :zoom="10" :center="center">
          <l-tile-layer :url="url" :attribution="attribution"></l-tile-layer>
          <l-geo-json v-if="departementGeo" :geojson="departementGeo" :options-style="styleDepartement" />

          <l-geo-json v-if="canton && init" :geojson="canton" :options-style="style" :options="options" />
        </l-map>
        <v-card
          style="width: 26%; position: absolute; top: 100px; left: 30px; z-index: 99999; background-color: rgba(255, 255, 255, 0.95)"
          v-if="Object.keys(searchedSectors).length > 0 && isGranted('SECTORS_AFFECTATION')"
          class="ma-3"
        >
          <v-toolbar color="primary " dark elevation="0">
            <v-row class="p-0 m-0 text-left">
              <v-col cols="12" large class="text-uppercase">
                Association / dissociation secteur
                <v-spacer></v-spacer>
                <v-icon small @click="searchedSectors = {}" style="position: absolute; top: 15px; right: 15px">fa-times</v-icon>
              </v-col>
            </v-row>
          </v-toolbar>
          <v-card-text>
            <v-chip label small class="ma-1" v-for="(sector, key) in Object.keys(searchedSectors)" :key="key">
              {{ searchedSectors[sector].item.name }}
            </v-chip>
          </v-card-text>
          <v-card-text>
            <list-user :multiple="false" @selectChange="setAsUser" includeAll></list-user>
          </v-card-text>
          <v-card-text>
            <v-row>
              <v-col cols="6" class="text-center">
                <v-btn color="error" text @click="removeAffects">Désaffecter</v-btn>
              </v-col>
              <v-col cols="6" class="text-left">
                <v-btn color="primary" @click="addAffects">Affecter</v-btn>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
        <v-card
          style="width: 30%; position: absolute; top: 0px; right: 30px; z-index: 99999; background-color: rgba(255, 255, 255, 0.92)"
          v-if="mouseOn"
          class="ma-3"
        >
          <v-toolbar color="primary " dark elevation="0">
            <v-row class="p-0 m-0 text-left">
              <v-col cols="8" large class="text-uppercase">
                {{ sectors[mouseOn].item.name }}
                <br />
                <small class="grey--text text--lighten-3">Code : {{ mouseOn }}</small>
              </v-col>
              <v-col cols="4" large class="text-uppercase text-right">
                <UserAvatar
                  v-if="sectors[mouseOn].users"
                  :userId="sectors[mouseOn].users.user.id"
                  :fullname="sectors[mouseOn].users.user.firstname + ' ' + sectors[mouseOn].users.user.lastname"
                />
                <span v-else>Non affecté</span>
              </v-col>
            </v-row>
          </v-toolbar>
          <v-card-text>
            <v-chip label x-small class="me-2 mt-2" v-for="(city, key) in cities[sectors[mouseOn].item.id]" :key="key">
              {{ city.name }}
            </v-chip>
          </v-card-text>
        </v-card>
      </div>
    </v-card>
  </v-container>
</template>

<script>
import { LMap, LTileLayer, LGeoJson } from "vue2-leaflet";
import canton from "./cantons_2015_simpl.json";
import departement from "./departements.json";
import simpleDebounce from "simple-debounce-vue-mixin";
import ListUser from "../../components/user/listUser";
import Tools from "../../mixins/Tools";
import UserAvatar from "../../components/user/UserAvatar";

export default {
  name: "sectorSettings",
  mixins: [simpleDebounce, Tools],
  props: {},
  components: { ListUser, LMap, LTileLayer, LGeoJson, UserAvatar },
  data() {
    return {
      init: false,
      canton: canton,
      searchedSectors: {},
      departementGeo: departement,
      fromFeature: this.$route.path === "/sectors",
      styleDepartement: {
        color: "red",
        fillColor: "transparent",
        dashArray: "5",
        weight: 2,
      },
      userSelected: null,
      types: [],
      selectedService: 1,
      sectors: {},
      mouseOn: null,
      sectorsTmp: {},
      cities: {},
      styleCanton: {
        fillColor: "#e00000",
        weight: 2,
        opacity: 0.2,
        color: "blue", //Outline color
        fillOpacity: 0.6,
      },
      url: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png",
      attribution: "&copy; Actuelburo",
      center: [43.7718693, -0.4637913],
    };
  },
  mounted() {
    this.getTypes();
    this.getCities();
  },
  methods: {
    addAffects() {
      if (this.userSelected === null) {
        this.$simpleToast.error("Secteur", "Aucun utilisateur séléctionné");
        return;
      }
      let tmp = [];
      Object.keys(this.searchedSectors).forEach((sector) => {
        tmp.push(this.searchedSectors[sector].item.id);
      });
      this.$axios.post("/api/sectors/users", { sectors: tmp, type: this.selectedService, user: this.userSelected }).then(() => {
        this.init = false;
        this.getSectors();
        this.$simpleToast.success("Secteur", "Les secteurs ont bien été affecté");
        this.searchedSectors = {};
      });
    },
    setAsUser(item) {
      this.userSelected = item;
    },
    async removeAffects() {
      let res = await this.$confirm("Êtes-vous sûr de vouloir désaffecter ?");

      if (res) {
        let tmp = [];
        Object.keys(this.searchedSectors).forEach((sector) => {
          tmp.push(this.searchedSectors[sector].item.id);
        });
        this.$axios.patch("/api/sectors/users", { sectors: tmp, type: this.selectedService }).then(() => {
          this.init = false;
          this.getSectors();
          this.$simpleToast.success("Secteur", "Les secteurs ont bien été désaffecté");
          this.searchedSectors = {};
        });
      }
    },
    getTypes() {
      this.$axios("/api/types/active").then((resp) => {
        this.types = resp.data.types;
        this.selectedService = this.types[0].id;
        this.getSectors();
      });
    },
    getSectorsUsers() {
      this.$axios("/api/sectors/users", { params: { type: this.selectedService } }).then((resp) => {
        resp.data.forEach((users) => {
          if (this.sectorsTmp[users.sectorId]) {
            this.sectorsTmp[users.sectorId].user = users;
          }
        });
        this.sectors = {};
        Object.keys(this.sectorsTmp).forEach((keyVal) => {
          this.sectors[this.sectorsTmp[keyVal].item.code] = { users: this.sectorsTmp[keyVal].user, item: this.sectorsTmp[keyVal].item };
        });
        this.init = true;
      });
    },
    getSectors() {
      this.sectorsTmp = {};
      this.$axios("/api/sectors").then((resp) => {
        resp.data.forEach((item) => {
          this.sectorsTmp[item.id] = { item: item };
        });
        this.getSectorsUsers();
      });
    },
    getCities() {
      this.$axios("/api/sectors/cities").then((resp) => {
        let tmp = {};
        resp.data.forEach((item) => {
          if (item.sectorId) {
            if (!tmp[parseInt(item.sectorId)]) {
              tmp[parseInt(item.sectorId)] = [];
            }
            tmp[parseInt(item.sectorId)].push(item);
          }
        });
        this.cities = tmp;
      });
    },
    getColorCanton(feature) {
      if (
        this.sectors[feature.properties.ref] &&
        this.sectors[feature.properties.ref].users &&
        this.sectors[feature.properties.ref].users.user &&
        this.sectors[feature.properties.ref].users.user.color
      ) {
        return this.sectors[feature.properties.ref].users.user.color;
      }
      return "transparent";
    },
  },
  computed: {
    style() {
      return (feature) => {
        if (this.searchedSectors[feature.properties.ref] && this.isGranted("SECTORS_AFFECTATION")) {
          return {
            fillColor: "#e00",
            weight: 2,
            opacity: 0.2,

            color: "blue", //Outline color
            fillOpacity: 0.6,
          };
        }
        return {
          fillColor: this.getColorCanton(feature),
          weight: 2,
          opacity: 0.2,

          color: "blue", //Outline color
          fillOpacity: 0.35,
        };
      };
    },
    options() {
      return {
        onEachFeature: this.onEachFeatureFunction,
      };
    },
    onEachFeatureFunction() {
      return (feature, layer) => {
        layer.on("mouseout", () => {
          this.mouseOn = null;
        });
        layer.on("mouseover", () => {
          this.mouseOn = feature.properties.ref;
        });
        layer.on("click", () => {
          let tmp = JSON.parse(JSON.stringify(this.canton));
          this.canton = null;
          this.init = false;

          if (this.searchedSectors[feature.properties.ref]) {
            this.$delete(this.searchedSectors, feature.properties.ref);
          } else {
            this.searchedSectors[feature.properties.ref] = this.sectors[feature.properties.ref];
          }

          this.canton = tmp;
          this.$forceUpdate();
          this.init = true;
        });
      };
    },
  },
  watch: {
    selectedService() {
      this.simpleDebounce(() => {
        this.init = false;
        this.searchedSectors = {};
        this.getSectors();
        this.$simpleToast.success("Secteur", "Le service a bien été changé");
      }, 500);
    },
  },
};
</script>

<style scoped></style>
