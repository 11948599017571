<template>
  <v-container fluid style="position: relative; padding-top: 0 !important" class="pt-0">
    <modal v-model="eventModal" max-width="1024">
      <template v-slot:header>
        <strong class="text-h5 mb-0 text-uppercase">Evènement</strong>
        <br /><small class="mt-0">
          <template v-if="event"> {{ dayjs(event.date).format("DD/MM/YYYY") }} {{ event.time }} - {{ event.name }} </template>
        </small>
        <template v-if="event">
          <template v-if="event.id">
            <div class="modal-menu--actions">
              <v-row>
                <v-col cols="12" class="text-left">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        small
                        elevation="0"
                        plain
                        color="primary"
                        @click="$router.push({ name: 'Client', params: { id: event.customerId } })"
                      >
                        <v-icon x-small>fa-search</v-icon>
                      </v-btn>
                    </template>
                    <span>Fiche client</span>
                  </v-tooltip>

                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-bind="attrs"
                        v-on="on"
                        class="ml-1"
                        small
                        elevation="0"
                        plain
                        color="none"
                        @click="
                          typeLink.id = event.id;
                          linkModal = true;
                        "
                      >
                        <v-icon x-small>fa-envelope</v-icon>
                      </v-btn>
                    </template>
                    <span>Lier un e-mail</span>
                  </v-tooltip>

                  <template v-if="event.opportunityId">
                    <v-tooltip bottom>
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          v-bind="attrs"
                          v-on="on"
                          class="mx-1"
                          elevation="0"
                          small
                          plain
                          color="warning"
                          @click="$router.push({ name: 'Opportunité', params: { id: event.opportunityId } })"
                        >
                          <v-icon x-small>fa-handshake</v-icon>
                        </v-btn>
                      </template>
                      <span>Opportunité {{ event.opportunityId }}</span>
                    </v-tooltip>
                  </template>
                  <template v-else>
                    <!-- <v-tooltip bottom>
                       <template v-slot:activator="{ on, attrs }">
                         <v-btn @click="newOpp" v-bind="attrs"
                                v-on="on" class="mx-1" elevation="0" small plain color="warning">
                           <v-icon x-small>fa-plus</v-icon>
                         </v-btn>
                       </template>
                       <span>Créer opportunité</span>
                     </v-tooltip>
                     <v-tooltip bottom>
                       <template v-slot:activator="{ on, attrs }">
                         <v-btn v-bind="attrs"
                                v-on="on" @click="linkOppModal = true" small plain elevation="0">
                           <v-icon x-small>fa-link</v-icon>
                         </v-btn>
                       </template>
                       <span>Associer opportunité</span>
                     </v-tooltip>
                     -->
                  </template>
                </v-col>
              </v-row>
            </div>
          </template>
        </template>
      </template>
      <v-form v-model="valid" ref="form">
        <event-form v-model="event"></event-form>
      </v-form>
      <template v-slot:actions>
        <v-btn variant="flat" color="primary" elevation="0" @click="editEventFct" :loading="loadingSave"> Enregistrer </v-btn>
        <v-btn color="secondary" text class="mr-4" @click="eventModal = false"> Fermer </v-btn>
      </template>
    </modal>

    <v-row style="position: relative">
      <v-col cols="1" class="text-center pt-0" style="top: -15px; left: 0; position: fixed; z-index: 1000; max-width: 7rem">
        <v-card style="height: 100vh; box-shadow: none; border: none" color="transparent" v-if="!isMobile()" @click="leftPanel = true">
          <div style="line-height: 100vh; padding-right: 1rem" class="text-right">
            <v-icon> fa-chevron-right </v-icon>
          </div>
          <v-container fluid>
            <v-expand-x-transition>
              <v-navigation-drawer v-if="leftPanel" fixed style="left: 50px; max-width: 720px; width: 100%">
                <v-list-item>
                  <v-list-item-title class="text-left">
                    <h3 class="text-uppercase blue-grey--text text--darken-1">Filtre textuel</h3>
                  </v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>
                <v-container class="mx-3" fluid>
                  <v-row>
                    <v-col cols="12">
                      <v-text-field class="ma-0 mt-0 pt-1" label="Client ou titre d'opportunité" v-model="searchValue" hide-details></v-text-field>
                    </v-col>
                  </v-row>
                </v-container>
                <v-list-item>
                  <v-list-item-title class="text-left">
                    <h3 class="text-uppercase blue-grey--text text--darken-1">Etat</h3>
                  </v-list-item-title>
                </v-list-item>
                <v-divider></v-divider>

                <v-container class="mx-3" fluid>
                  <v-row>
                    <v-col cols="6" class="ma-0 py-0">
                      <v-switch v-model="open" color="success" label="Ouvert"></v-switch>
                    </v-col>
                    <v-col cols="6" class="ma-0 py-0">
                      <v-switch v-model="close" color="error" label="Fermé"></v-switch>
                    </v-col>
                  </v-row>
                </v-container>

                <v-list-item v-if="isGranted('OPPORTUNITIES_INDEX_ALLSERVICE')">
                  <h3 class="text-uppercase blue-grey--text text--darken-1">Métiers</h3>
                </v-list-item>
                <v-divider v-if="isGranted('OPPORTUNITIES_INDEX_ALLSERVICE')"></v-divider>
                <v-container class="mx-3" fluid v-if="isGranted('OPPORTUNITIES_INDEX_ALLSERVICE')">
                  <v-row class="mt-2">
                    <v-col cols="4" class="ma-0 py-0" v-for="(item, key) in jobs" :key="key">
                      <v-switch class="ma-1 py-0" v-model="selectedJobs" :value="item.id">
                        <template v-slot:label>
                          <span style="font-size: 0.875rem">{{ item.name }}</span>
                        </template>
                      </v-switch>
                    </v-col>
                  </v-row>
                </v-container>

                <v-list-item>
                  <h3 class="text-uppercase blue-grey--text text--darken-1">Services</h3>
                </v-list-item>
                <v-divider></v-divider>
                <v-btn-toggle class="mt-3">
                  <v-btn small color="primary" @click="selectAllUsers">Tous</v-btn>

                  <v-btn small color="secondary" @click="asUser = []">Aucun</v-btn>
                </v-btn-toggle>
                <v-container class="mr-3" fluid v-if="isGranted('OPPORTUNITIES_INDEX_ALLSERVICE')">
                  <v-tabs v-model="tab" center-active grow show-arrows>
                    <v-tab style="font-size: 0.875rem" v-for="(service, idSer) in services" :key="idSer">
                      {{ idSer }}
                    </v-tab>
                  </v-tabs>
                  <v-tabs-items v-model="tab">
                    <v-tab-item v-for="(service, idSer) in services" :key="idSer">
                      <v-row class="ma-3 pt-4">
                        <v-col cols="4" v-for="(item, key) in service" :key="key" class="py-1 switchUser">
                          <v-switch class="ma-0 pa-0" v-model="asUser" :value="item.id">
                            <template v-slot:label>
                              <span style="font-size: 0.875rem">{{ item.firstname + " " + item.lastname }}</span>
                            </template>
                          </v-switch>
                        </v-col>
                      </v-row>
                    </v-tab-item>
                  </v-tabs-items>
                </v-container>
              </v-navigation-drawer>
            </v-expand-x-transition>
          </v-container>
        </v-card>
      </v-col>
      <v-col
        v-if="!isMobile()"
        cols="12"
        @click="
          leftPanel = false;
          rightPanel = false;
        "
        style="min-height: 96vh"
      >
        <v-container fluid class="mt-0">
          <div style="position: relative; width: 100%">
            <v-btn
              @click="newOpp"
              v-if="isGranted('OPPORTUNITIES_INDEX')"
              small
              fab
              style="position: absolute; left: 0; top: -2.7rem; z-index: 99"
              color="primary"
            >
              <v-icon small>fa-plus fa-fw</v-icon>
            </v-btn>
          </div>
          <v-row style="margin-top: -35px; max-height: 96vh; overflow-y: scroll" id="infinite-list">
            <v-col cols="12" md="4" style="position: relative" class="px-0">
              <div class="text-center primary--text text-uppercase font-weight-bold mb-3" style="letter-spacing: 2px">Identifié</div>
              <div>
                <v-col cols="12" class="py-1 my-1" v-for="(item, key) in tasks[0]" :key="key">
                  <dashboard-card :item="item"></dashboard-card>
                </v-col>
              </div>
            </v-col>
            <v-col cols="12" md="4" style="" class="px-0">
              <div class="text-center primary--text text-uppercase font-weight-bold mb-3" style="letter-spacing: 2px">Étude</div>
              <div>
                <v-col cols="12" class="py-2 my-1" v-for="(item, key) in tasks[1]" :key="key">
                  <dashboard-card :item="item"></dashboard-card>
                </v-col>
              </div>
            </v-col>
            <v-col cols="12" md="4" class="px-0">
              <div class="text-center primary--text text-uppercase font-weight-bold mb-3" style="letter-spacing: 2px">Négociation</div>
              <div>
                <v-col cols="12" class="py-2 my-1" v-for="(item, key) in tasks[2]" :key="key">
                  <dashboard-card :item="item"></dashboard-card>
                </v-col>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col
        v-else
        cols="12"
        class="ma-0 pa-0"
        @click="
          leftPanel = false;
          rightPanel = false;
        "
        style="min-height: 96vh"
      >
        <v-expansion-panels variant="accordion">
          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="text-center primary--text text-uppercase font-weight-bold mb-3" style="letter-spacing: 2px">Identifié</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div>
                <v-col cols="12" class="py-1 my-1" v-for="(item, key) in tasks[0]" :key="key">
                  <dashboard-card :item="item"></dashboard-card>
                </v-col>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="text-center primary--text text-uppercase font-weight-bold mb-3" style="letter-spacing: 2px">Etude</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div>
                <v-col cols="12" class="py-1 my-1" v-for="(item, key) in tasks[1]" :key="key">
                  <dashboard-card :item="item"></dashboard-card>
                </v-col>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>
              <div class="text-center primary--text text-uppercase font-weight-bold mb-3" style="letter-spacing: 2px">Négociation</div>
            </v-expansion-panel-header>
            <v-expansion-panel-content>
              <div>
                <v-col cols="12" class="py-1 my-1" v-for="(item, key) in tasks[2]" :key="key">
                  <dashboard-card :item="item"></dashboard-card>
                </v-col>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-col>
      <div offset="11" class="text-left" style="top: 0px; right: 0; bottom: 0; width: 40px; position: fixed; z-index: 1000; padding-right: 5px">
        <v-hover v-slot="{ hover }">
          <v-card style="height: 96vh; box-shadow: none; border: none" color="transparent">
            <div style="line-height: 96vh" class="text-right" v-if="!isMobile()">
              <v-icon class="mr-4"> fa-chevron-left </v-icon>
            </div>
            <v-expand-x-transition>
              <v-navigation-drawer v-if="hover" fixed right width="600" color="#f9fafb">
                <v-toolbar class="ma-0" elevation="0" color="primary" dark>
                  <v-row align="center">
                    <v-col>
                      <v-btn icon class="" @click="changeLastMonday(-7)">
                        <v-icon>fa-chevron-left</v-icon>
                      </v-btn>
                    </v-col>
                    <v-col class="text-center">
                      <v-chip class=" " outlined
                        >Du {{ dayjs(lastMonday).format("DD/MM/YYYY") }} au
                        {{ dayjs(lastSaturday).format("DD/MM/YYYY") }}
                      </v-chip>
                    </v-col>
                    <v-col class="text-right">
                      <v-btn icon @click="changeLastMonday(7)">
                        <v-icon>fa-chevron-right</v-icon>
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-toolbar>
                <v-container fluid>
                  <v-row>
                    <v-list
                      class="ma-0 pa-0 overflow-y-auto"
                      subheader
                      two-line
                      style="max-height: calc(100vh - 70px)"
                      dense
                      width="100%"
                      v-if="Object.keys(events).length > 0 && eventsLoaded"
                    >
                      <v-list-item-group v-for="(value, date) in Object.keys(events)" :key="date">
                        <v-subheader class="title mt-2 mb-0 font-italic">
                          {{ dayjs(value).format("dddd DD MMM YYYY") }}
                        </v-subheader>
                        <v-list-item-group v-for="(item, key) in events[value]" :key="key">
                          <v-divider inset style="margin-left: 60px; max-width: calc(100% - 60px)"></v-divider>
                          <v-list-item
                            class="my-0 py-0"
                            @click="
                              leftPanel = false;
                              loadEvent(item);
                            "
                            style="min-height: 56px"
                            dense
                          >
                            <v-list-item-avatar size="30" class="my-0">
                              <v-icon dark x-small :style="'background-color:' + eventsList.type[item.init.eventtypeId].color">
                                {{ eventsList.type[item.init.eventtypeId].icon }} fa-fw
                              </v-icon>
                            </v-list-item-avatar>

                            <v-list-item-content class="text-left py-0">
                              <v-list-item-title class="primary--text"
                                >{{ eventsList.entreprise[item.init.id].name }}
                                <template v-if="item.init.opportunity">
                                  <v-chip x-small outlined class="ml-2" label color="primary">
                                    <v-icon start class="mr-1">far fa-handshake fa-fw</v-icon>
                                    {{ item.init.opportunity.id }}
                                  </v-chip>
                                </template>
                              </v-list-item-title>

                              <v-list-item-subtitle>{{ item.init.name }}</v-list-item-subtitle>
                            </v-list-item-content>
                            <v-list-item-action class="ma-0">
                              {{ dayjs(item.init.start).format("HH:mm") }} -
                              {{ dayjs(item.init.end).format("HH:mm") }}
                            </v-list-item-action>
                          </v-list-item>
                        </v-list-item-group>

                        <v-divider inset style="margin-left: 60px; max-width: calc(100% - 60px)"></v-divider>
                      </v-list-item-group>
                    </v-list>
                    <template v-if="!eventsLoaded">
                      <v-row align="center" style="width: 100%">
                        <v-col class="text-center my-10">
                          <v-progress-circular :size="40" color="primary" indeterminate class="my-3"></v-progress-circular>
                          <p class="text-caption">Chargement des données</p>
                        </v-col>
                      </v-row>
                    </template>
                    <template v-if="eventsLoaded && Object.keys(events).length == 0">
                      <v-row align="center" style="width: 100%">
                        <v-col class="text-center my-10">
                          <v-icon size="40" class="my-3">far fa-times-circle</v-icon>
                          <p class="text-caption">Aucun évènement pour cette période</p>
                        </v-col>
                      </v-row>
                    </template>
                  </v-row>
                </v-container>
              </v-navigation-drawer>
            </v-expand-x-transition>
          </v-card>
        </v-hover>
      </div>
    </v-row>

    <v-row v-if="false" style="margin-top: -35px">
      <v-col cols="12" md="4" style="position: relative" class="px-0">
        <div class="text-center primary--text text-uppercase font-weight-bold">Identifié</div>
        <div>
          <v-col cols="12" class="py-1 my-1" v-for="(item, key) in tasks[0]" :key="key">
            <v-card hover :color="returnCardColor(item.opp.statusId)" @click="openOpp(item.opp.id)">
              <v-card-title>
                <v-row>
                  <v-col cols="8" class="py-0 my-0">
                    <div style="width: 100%">
                      <h6 class="primary--text">
                        {{ item.opp.id }} - <span style="font-size: 1rem; color: grey" v-if="item.entreprise">{{ item.entreprise.name }}</span>
                      </h6>
                    </div>
                    <div>
                      <h5 class="primary--text">{{ item.opp.name }}</h5>
                    </div>
                  </v-col>
                  <v-col cols="4" class="text-right py-0 my-0" v-if="item.typeArray">
                    <v-chip label small :color="item.typeArray.color" text-color="white">{{ item.typeArray.name }} </v-chip>
                  </v-col>
                </v-row>
              </v-card-title>

              <v-card-text>
                <v-row>
                  <v-col cols="6">
                    <v-icon x-small :color="item.opp.probability >= 25 ? '#fdd835' : ''">fa-star</v-icon>
                    <v-icon x-small :color="item.opp.probability >= 50 ? '#fdd835' : ''">fa-star</v-icon>
                    <v-icon x-small :color="item.opp.probability >= 75 ? '#fdd835' : ''">fa-star</v-icon>
                    <v-icon x-small :color="item.opp.probability === 100 ? '#fdd835' : ''">fa-star</v-icon>
                  </v-col>
                  <v-col cols="6" class="text-right py-0 my-0">
                    <h4 v-if="item.opp.amount">{{ numStr(item.opp.amount) }} €</h4>
                  </v-col>
                </v-row>
                <template v-if="item.nextEvent">
                  <v-divider class="my-1"></v-divider>
                  <v-container fluid>
                    <v-row>
                      <v-col cols="2" class="py-1">
                        <v-icon small>{{ item.nextEvent.event.eventtypeId === 1 ? "fa-phone" : "fa-calendar" }}</v-icon>
                      </v-col>
                      <v-col cols="6" class="py-1">
                        <template v-if="item.nextEvent.event.start">
                          {{ dayjs(item.nextEvent.event.start).format("dddd DD-MM-YYYY HH:mm") }} -
                          {{ item.nextEvent.event.name }}
                        </template>
                      </v-col>
                      <v-col cols="4" v-if="item.nextEvent.contact" class="font-italicpy-1">
                        {{ item.nextEvent.contact.firstname + " " + item.nextEvent.contact.lastname }}
                      </v-col>
                    </v-row>
                  </v-container>
                </template>
                <v-divider class="my-1"></v-divider>
                <v-row>
                  <v-col cols="2" class="pt-2 pb-0">
                    <UserAvatar :userId="item.opp.userId" size="35" />
                  </v-col>
                  <v-col cols="5" class="text-right mt-1 pb-0" v-if="item.opp.deadline">
                    <h4 :class="{ 'error--text': getComparation(item.opp.deadline, item.opp.statusId) }">
                      {{ dayjs(item.opp.deadline).format("DD-MM-YYYY") }}
                    </h4>
                  </v-col>
                  <v-col cols="5" class="text-right mt-1 pb-0">
                    <v-row>
                      <v-col cols="6" class="text-right" v-if="item.comments.length > 0" @click="openModalComments(item.comments)">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on"> <v-icon class="mr-2" small>fa-comments</v-icon> {{ item.comments.length }} </span>
                          </template>
                          <span>
                            <div v-for="(comments, key) in item.comments" :key="key">
                              <v-row>
                                <v-col cols="2">
                                  <UserAvatar :userId="comments.userId" size="50" />
                                </v-col>
                                <v-col cols="10">
                                  <v-card elevation="2" width="250" class="pa-2">
                                    <span class="font-italic">{{ dayjs(comments.createdAt).format("DD/MM/YYYY à HH:mm") }}</span>
                                    <p>{{ comments.body }}</p>
                                  </v-card>
                                </v-col>
                              </v-row>
                            </div>
                          </span>
                        </v-tooltip>
                      </v-col>
                      <v-col cols="6" class="text-right" v-if="item.medias > 0">
                        <v-icon class="mr-2" small>fas fa-paperclip</v-icon>
                        {{ item.medias }}
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </div>
      </v-col>
      <v-col cols="12" md="4" style="" class="px-0">
        <div class="text-center primary--text text-uppercase font-weight-bold">Étude</div>
        <div>
          <v-col cols="12" class="py-1 my-1" v-for="(item, key) in tasks[1]" :key="key">
            <v-card hover :color="returnCardColor(item.opp.statusId)" @click="openOpp(item.opp.id)">
              <v-card-title>
                <v-row>
                  <v-col cols="8" class="py-0 my-0">
                    <div style="width: 100%">
                      <h6 class="primary--text">
                        {{ item.opp.id }} - <span style="font-size: 1rem; color: grey" v-if="item.entreprise">{{ item.entreprise.name }}</span>
                      </h6>
                    </div>
                    <div>
                      <h5 class="primary--text">{{ item.opp.name }}</h5>
                    </div>
                  </v-col>
                  <v-col cols="4" class="text-right py-0 my-0" v-if="item.typeArray">
                    <v-chip label small :color="item.typeArray.color" text-color="white">{{ item.typeArray.name }} </v-chip>
                  </v-col>
                </v-row>
              </v-card-title>

              <v-card-text>
                <v-row>
                  <v-col cols="6">
                    <v-icon x-small :color="item.opp.probability >= 25 ? '#fdd835' : ''">fa-star</v-icon>
                    <v-icon x-small :color="item.opp.probability >= 50 ? '#fdd835' : ''">fa-star</v-icon>
                    <v-icon x-small :color="item.opp.probability >= 75 ? '#fdd835' : ''">fa-star</v-icon>
                    <v-icon x-small :color="item.opp.probability === 100 ? '#fdd835' : ''">fa-star</v-icon>
                  </v-col>
                  <v-col cols="6" class="text-right py-0 my-0">
                    <h4 v-if="item.opp.amount">{{ numStr(item.opp.amount) }} €</h4>
                  </v-col>
                </v-row>
                <template v-if="item.nextEvent">
                  <v-divider class="my-1"></v-divider>
                  <v-container fluid>
                    <v-row>
                      <v-col cols="2" class="py-1">
                        <v-icon small>{{ item.nextEvent.event.eventtypeId === 1 ? "fa-phone" : "fa-calendar" }}</v-icon>
                      </v-col>
                      <v-col cols="6" class="py-1">
                        <template v-if="item.nextEvent.event.start">
                          {{ dayjs(item.nextEvent.event.start).format("dddd DD-MM-YYYY HH:mm") }} -
                          {{ item.nextEvent.event.name }}
                        </template>
                      </v-col>
                      <v-col cols="4" v-if="item.nextEvent.contact" class="font-italicpy-1">
                        {{ item.nextEvent.contact.firstname + " " + item.nextEvent.contact.lastname }}
                      </v-col>
                    </v-row>
                  </v-container>
                </template>
                <v-divider class="my-1"></v-divider>
                <v-row>
                  <v-col cols="2" class="pt-2 pb-0">
                    <UserAvatar :userId="item.opp.userId" size="35" />
                  </v-col>
                  <v-col cols="5" class="text-right mt-1 pb-0" v-if="item.opp.deadline">
                    <h4 :class="{ 'error--text': getComparation(item.opp.deadline, item.opp.statusId) }">
                      {{ dayjs(item.opp.deadline).format("DD-MM-YYYY") }}
                    </h4>
                  </v-col>
                  <v-col cols="5" class="text-right mt-1 pb-0">
                    <v-row>
                      <v-col cols="6" class="text-right" v-if="item.comments.length > 0" @click="openModalComments(item.comments)">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on"> <v-icon class="mr-2" small>fa-comments</v-icon> {{ item.comments.length }} </span>
                          </template>
                          <span>
                            <div v-for="(comments, key) in item.comments" :key="key">
                              <v-row>
                                <v-col cols="2">
                                  <UserAvatar :userId="comments.userId" size="50" />
                                </v-col>
                                <v-col cols="10">
                                  <v-card elevation="2" width="250" class="pa-2">
                                    <span class="font-italic">{{ dayjs(comments.createdAt).format("DD/MM/YYYY à HH:mm") }}</span>
                                    <p>{{ comments.body }}</p>
                                  </v-card>
                                </v-col>
                              </v-row>
                            </div>
                          </span>
                        </v-tooltip>
                      </v-col>
                      <v-col cols="6" class="text-right" v-if="item.medias > 0">
                        <v-icon class="mr-2" small>fas fa-paperclip</v-icon>
                        {{ item.medias }}
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </div>
      </v-col>
      <v-col cols="12" md="4" class="px-0">
        <div class="text-center primary--text text-uppercase font-weight-bold">Négociation</div>
        <div>
          <v-col cols="12" class="py-1 my-1" v-for="(item, key) in tasks[2]" :key="key">
            <v-card hover :color="returnCardColor(item.opp.statusId)" @click="openOpp(item.opp.id)">
              <v-card-title>
                <v-row>
                  <v-col cols="8" class="py-0 my-0">
                    <div style="width: 100%">
                      <h6 class="primary--text">
                        {{ item.opp.id }} - <span style="font-size: 1rem; color: grey" v-if="item.entreprise">{{ item.entreprise.name }}</span>
                      </h6>
                    </div>
                    <div>
                      <h5 class="primary--text">{{ item.opp.name }}</h5>
                    </div>
                  </v-col>
                  <v-col cols="4" class="text-right py-0 my-0" v-if="item.typeArray">
                    <v-chip label small :color="item.typeArray.color" text-color="white">{{ item.typeArray.name }} </v-chip>
                  </v-col>
                </v-row>
              </v-card-title>

              <v-card-text>
                <v-row>
                  <v-col cols="6">
                    <v-icon x-small :color="item.opp.probability >= 25 ? '#fdd835' : ''">fa-star</v-icon>
                    <v-icon x-small :color="item.opp.probability >= 50 ? '#fdd835' : ''">fa-star</v-icon>
                    <v-icon x-small :color="item.opp.probability >= 75 ? '#fdd835' : ''">fa-star</v-icon>
                    <v-icon x-small :color="item.opp.probability === 100 ? '#fdd835' : ''">fa-star</v-icon>
                  </v-col>
                  <v-col cols="6" class="text-right py-0 my-0">
                    <h4 v-if="item.opp.amount">{{ numStr(item.opp.amount) }} €</h4>
                  </v-col>
                </v-row>
                <template v-if="item.nextEvent">
                  <v-divider class="my-1"></v-divider>
                  <v-container fluid>
                    <v-row>
                      <v-col cols="2" class="py-1">
                        <v-icon small>{{ item.nextEvent.event.eventtypeId === 1 ? "fa-phone" : "fa-calendar" }}</v-icon>
                      </v-col>
                      <v-col cols="6" class="py-1">
                        <template v-if="item.nextEvent.event.start">
                          {{ dayjs(item.nextEvent.event.start).format("dddd DD-MM-YYYY HH:mm") }} -
                          {{ item.nextEvent.event.name }}
                        </template>
                      </v-col>
                      <v-col cols="4" v-if="item.nextEvent.contact" class="font-italicpy-1">
                        {{ item.nextEvent.contact.firstname + " " + item.nextEvent.contact.lastname }}
                      </v-col>
                    </v-row>
                  </v-container>
                </template>
                <v-divider class="my-1"></v-divider>
                <v-row>
                  <v-col cols="2" class="pt-2 pb-0">
                    <UserAvatar :userId="item.opp.userId" size="35" />
                  </v-col>
                  <v-col cols="5" class="text-right mt-1 pb-0" v-if="item.opp.deadline">
                    <h4 :class="{ 'error--text': getComparation(item.opp.deadline, item.opp.statusId) }">
                      {{ dayjs(item.opp.deadline).format("DD-MM-YYYY") }}
                    </h4>
                  </v-col>
                  <v-col cols="5" class="text-right mt-1 pb-0">
                    <v-row>
                      <v-col cols="6" class="text-right" v-if="item.comments.length > 0" @click="openModalComments(item.comments)">
                        <v-tooltip bottom>
                          <template v-slot:activator="{ on, attrs }">
                            <span v-bind="attrs" v-on="on"> <v-icon class="mr-2" small>fa-comments</v-icon> {{ item.comments.length }} </span>
                          </template>
                          <span>
                            <div v-for="(comments, key) in item.comments" :key="key">
                              <v-row>
                                <v-col cols="2">
                                  <UserAvatar :userId="comments.userId" size="50" />
                                </v-col>
                                <v-col cols="10">
                                  <v-card elevation="2" width="250" class="pa-2">
                                    <span class="font-italic">{{ dayjs(comments.createdAt).format("DD/MM/YYYY à HH:mm") }}</span>
                                    <p>{{ comments.body }}</p>
                                  </v-card>
                                </v-col>
                              </v-row>
                            </div>
                          </span>
                        </v-tooltip>
                      </v-col>
                      <v-col cols="6" class="text-right" v-if="item.medias > 0">
                        <v-icon class="mr-2" small>fas fa-paperclip</v-icon>
                        {{ item.medias }}
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import simpleDebounce from "simple-debounce-vue-mixin";
import dayjs from "dayjs";
import Modal from "../components/common/modal";
import EventForm from "../components/common/EventForm";
import Tools from "../mixins/Tools";
import DashboardCard from "../components/common/dashboardCard";
import EventsTools from "../mixins/Events";
import MobileDetection from "@/mixins/MobileDetection";
import { EventBus } from "@/event-bus";
import UserAvatar from "../components/user/UserAvatar.vue";

export default {
  name: "OppPanel",
  components: { DashboardCard, EventForm, Modal, UserAvatar },
  mixins: [simpleDebounce, Tools, EventsTools, MobileDetection],
  data() {
    return {
      page: 1,
      tab: false,
      leftPanel: false,
      rightPanel: false,
      loadingSave: false,
      settings: false,
      asUser: [this.$store.state.user.id],
      asUserCal: [],
      tasks: {
        0: [],
        1: [],
        2: [],
      },
      selectedJobs: [],
      open: null,
      close: false,
      jobs: [],
      usersList: [],
      searchValue: "",
      calendar: false,
      commentsModal: false,
      comments: [],
      colors: ["blue", "indigo", "deep-purple", "cyan", "green", "orange", "grey darken-1"],
      events: [],
      lastMonday: null,
      lastSaturday: null,
      event: null,
      eventModal: false,
      eventsList: [],
      currentCustomer: null,
      valid: false,
      eventsLoaded: false,
      services: {},
    };
  },
  created() {
    EventBus.$on("hideLeftLayout", () => {
      this.leftPanel = false;
    });
  },
  mounted() {
    let prevMonday = new Date();
    let saturday = new Date();

    if (dayjs(prevMonday).format("dddd") !== "lundi") {
      this.lastMonday = this.nextDate(new Date(), 1, true);
    } else {
      this.lastMonday = prevMonday.setDate(new Date().getDate());
    }
    this.lastSaturday = this.nextDate(saturday, 0);
    // this.lastSaturday = saturday.setDate(prevMonday.getDate() + 6);

    this.getJobs();
    this.getAllUsers();
    this.getCalendar(1);
    if (sessionStorage.getItem("asUser")) {
      let tmp = sessionStorage.getItem("asUser").split(",");
      let tmp2 = [];
      tmp.forEach((item) => {
        tmp2.push(parseInt(item));
      });
      this.asUser = tmp2;
    }

    if (sessionStorage.getItem("selectedJobs")) {
      let tmp3 = sessionStorage.getItem("selectedJobs").split(",");
      let tmp4 = [];
      tmp3.forEach((item) => {
        tmp4.push(parseInt(item));
      });
      this.selectedJobs = tmp4;
    }

    if (sessionStorage.getItem("open")) {
      let tmp = sessionStorage.getItem("open");
      if (tmp === "false") {
        this.close = true;
      } else {
        this.open = true;
      }
      this.$forceUpdate();
    } else {
      this.open = true;
    }

    // Detect when scrolled to bottom.
    const listElm = document.querySelector("#infinite-list");

    try {
      listElm.addEventListener("scroll", () => {
        if (listElm.scrollTop + listElm.clientHeight >= listElm.scrollHeight) {
          this.loadMore();
        }
      });
    } catch (e) {
      console.log("No users displayed");
    }
  },
  methods: {
    loadMore() {
      this.getOpps(this.page + 1);
    },
    nextDate(today, dayIndex, monday = false) {
      if (monday) {
        today.setDate(today.getDate() + ((dayIndex - 1 - today.getDay() + 7) % 7) + 1 - 7);
      } else {
        today.setDate(today.getDate() + ((dayIndex - 1 - today.getDay() + 7) % 7) + 1);
      }
      return today;
    },
    changeLastMonday(day) {
      let prevMonday = new Date(this.lastMonday);
      //let saturday = new Date();
      prevMonday.setDate(prevMonday.getDate() - ((prevMonday.getDay() + 6) % 7) + day);
      this.lastMonday = prevMonday;
      this.lastSaturday = this.nextDate(new Date(prevMonday), 0);
    },
    async editEventFct() {
      let res = false;
      this.$refs.form.validate();
      if (!this.valid) {
        this.$simpleToast.error("Evènements", "Un des champs est vide.");
      } else {
        this.loadingSave = true;
        res = await this.editEventSubmit(this.event);
        this.loadingSave = false;
      }

      if (res) {
        this.getCalendar();
        this.eventModal = false;
        this.loadingSave = false;
      }
    },
    linkOppFct(idOpp) {
      this.$axios.patch("/api/events/" + this.event.id + "/link", { opp: idOpp }).then(() => {
        this.linkOppModal = false;
        this.addEvent = false;
        this.getEvents();
        this.$simpleToast.success("Evènements", "L'élément a bien été lié");
      });
    },
    newOpp() {
      let params = {};
      if (this.event) params = { event: this.event };
      this.$axios.post("/api/opportunities", params).then((res) => {
        if (this.event) {
          this.linkOppFct(res.data.opp.id);
        }
        this.$router.push({ name: "Opportunité", params: { id: res.data.opp.id } });
      });
    },
    async eventModalFct() {
      let res = false;
      this.$refs.form.validate();

      if (!this.valid) {
        this.$simpleToast.error("Evènement", "Un des champs est vide.");
      } else {
        this.loadingSave = true;
        res = await this.addEventSubmit(this.event);
        this.loadingSave = false;
      }

      if (res) {
        this.getCalendar();
        this.eventModal = false;
        this.loadingSave = false;
      }
    },
    loadEvent(tmp) {
      if (!tmp || !tmp.init) {
        return null;
      }

      let item = JSON.parse(JSON.stringify(tmp.init));

      let dateStart = dayjs(item.start).format("DD/MM/YYYY");
      let timeStart = dayjs(item.start).format("HH:mm");

      let tmpContacts = [];

      if (this.eventsList.info[item.id]) {
        this.eventsList.info[item.id].forEach((val) => {
          tmpContacts.push(val.contactId);
        });
      }
      this.currentCustomer = this.eventsList.entreprise[item.id];

      this.event = {
        id: item.id,
        opp: this.$route.params.id,
        eventtypeId: item.eventtypeId,
        opportunityId: item.opportunity ? item.opportunity.id : null,
        name: item.name,
        time: timeStart,
        date: dateStart,
        usersList: this.eventsList.users[item.id],
        duration: item.duration,
        private: item.private,
        teams: item.teams,
        status: item.status,
        body: item.body,
        contacts: tmpContacts,
        customer: item.customerId,
        customerId: item.customerId,
        history: [],
        report: item.report,
        ewsid: item.ewsid,
      };
      this.addEvent = true;

      // this.event.customerId = item.init.customer.id
      this.eventModal = true;
    },
    rnd(a, b) {
      return Math.floor((b - a + 1) * Math.random()) + a;
    },
    getCalendar() {
      this.eventsLoaded = false;
      this.eventsList = [];
      let start = dayjs(this.lastMonday).format("YYYY-MM-DD");
      let events = {};

      this.$axios(`/api/events/user`, {
        params: {
          start: start,
          addDay: 5,
          asUser: this.asUser,
          status: 0,
        },
      }).then((res) => {
        let pushitem;

        this.eventsList = res.data;
        res.data.events.forEach((item) => {
          pushitem = {
            name: item.name,
            start: dayjs(item.start).format("YYYY-MM-DD HH:mm:ss"),
            end: dayjs(item.end).format("YYYY-MM-DD HH:mm:ss"),
            color: this.colors[this.rnd(0, this.colors.length - 1)],
            init: item,
          };

          if (this.eventsList.type[item.eventtypeId] && this.eventsList.type[item.eventtypeId].inForm) {
            if (!events[dayjs(item.start).format("YYYY-MM-DD")]) {
              events[dayjs(item.start).format("YYYY-MM-DD")] = [];
            }
            events[dayjs(item.start).format("YYYY-MM-DD")].push(pushitem);
          }
        });
        this.events = events;
        this.eventsLoaded = true;
      });
    },
    numStr(nbr) {
      var nombre = "" + nbr;
      var retour = "";
      var count = 0;
      for (var i = nombre.length - 1; i >= 0; i--) {
        if (count != 0 && count % 3 == 0) retour = nombre[i] + " " + retour;
        else retour = nombre[i] + retour;
        count++;
      }
      let tmp = retour.split(".");

      return tmp[0];
    },
    openModalComments(comments) {
      this.comments = comments;
      this.commentsModal = true;
    },
    selectAllUsers() {
      this.usersList.forEach((item) => {
        this.asUser.push(item.id);
      });
    },
    getAllUsers() {
      this.$axios("/api/users", { params: { limit: false, active: true } }).then((resp) => {
        this.usersList = resp.data;

        const services = {};
        this.usersList.forEach((u) => {
          if (u.service && u.service.name) {
            if (!services[u.service.name]) services[u.service.name] = [];

            services[u.service.name].push(u);
          }
        });

        this.services = services;
      });
    },
    returnCardColor(id) {
      if (id === null) return "";
      if (id === 1) return "success lighten-1";
      if (id === 2) return "error lighten-1";
    },
    setAsUser(event) {
      this.asUser = event;
    },
    openOpp(id) {
      this.$router.push("/opportunities/edit/" + id);
    },
    getComparation(deadline, statusId) {
      let date = this.dayjs().unix();
      if (statusId) return false;
      if (this.dayjs(deadline).unix() <= date) return true;
      return false;
    },
    getJobs() {
      this.$axios("/api/types/active").then((resp) => {
        this.jobs = resp.data.types;
      });
    },
    getOpps(page = 1) {
      this.page = page;
      let search = {
        asUser: this.asUser,
        open: this.open,
        jobs: this.selectedJobs,
        search: this.searchValue,
        page: this.page,
      };
      if (page === 1) {
        this.tasks = {
          0: [],
          1: [],
          2: [],
        };
      }

      this.$axios("/api/opportunities/user", { params: { search } }).then((result) => {
        result.data.forEach((item) => {
          this.tasks[item.opp.stepId > 0 ? item.opp.stepId - 1 : 0].push(item);
        });
      });
    },
  },
  watch: {
    lastMonday() {
      this.eventsLoaded = false;
      this.simpleDebounce(() => {
        this.getCalendar(1);
      }, 750);
    },
    asUser() {
      sessionStorage.setItem("asUser", this.asUser);
      this.simpleDebounce(() => {
        this.getOpps(1);
        this.getCalendar();
      }, 500 /* optional debounce time: default is 300 */);
    },
    open() {
      this.close = !this.open;
      sessionStorage.setItem("open", this.open);

      this.simpleDebounce(() => {
        this.getOpps(1);
        this.getCalendar();
      }, 500 /* optional debounce time: default is 300 */);
    },
    close() {
      this.open = !this.close;
      sessionStorage.setItem("open", this.open);
    },
    selectedJobs() {
      sessionStorage.setItem("selectedJobs", this.selectedJobs);

      this.simpleDebounce(() => {
        this.getOpps(1);
        this.getCalendar();
      }, 500 /* optional debounce time: default is 300 */);
    },
    searchValue() {
      this.simpleDebounce(() => {
        this.getOpps(1);
        this.getCalendar();
      }, 500 /* optional debounce time: default is 300 */);
    },
  },
};
</script>

<style>
.switchUser .v-messages {
  display: none;
}
</style>
